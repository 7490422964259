* {
  font-family: 'Cabin', sans-serif;
}
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
.loader:empty {
  background-image: url(img/headcutout.png);
  background-size: cover;
  position: absolute;
  top: 35%;
  left: 43%;
  width: 150px;
  height: 160px;
  /* border: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000000;
  border-radius: 50%; */
  animation: spin 1.6s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* --------------------------------------HIDE-MOBILE------------------------------- */
.first-section-mobile {
  display: none;
}
.bio-section-mobile {
  display: none;
}
.projects-section-mobile {
  display: none;
}



/* --------------------------------------FIRST-SECTION------------------------------- */
.first-section {
  width: 100vw;
  height: 1500px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: -100%;
  transition: opacity 700ms, left 700ms;
}
.left-side {
  background-color: rgb(117, 117, 117);
  transition: width 700ms, background-color 1000ms;
  height: 1500px;
  width: 50vw;
  display: inline-block;
  position: absolute;
  left: 0;
  z-index: 2;
  /* transition-delay: 200ms; */
  transition-timing-function: cubic-bezier(0.1, 0.2, 1.0, 0.7); 
}
.right-side {
  background-color: rgb(238, 238, 238);
  height: 1500px;
  width: 100vw;
  position: absolute;
  left: 0;
  z-index: 1;
  display: inline-block;
  transition-delay: 200ms;
  transition: width 700ms;
  transition-timing-function: cubic-bezier(0.1, 0.2, 1.0, 0.7); 
}
.hej-wrapper {
  width: 80%;
  margin-left: 10%;
  height: 200px;
  margin-top: 38vh;
  position: fixed;
  z-index: 4;
  transition: left 600ms;
  transition-timing-function: cubic-bezier(0.4, 0.6, 0.7, 1); 
  /* transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1); */
}
.hej {
  position: relative;
  display: inline-block;
  color: rgb(160, 160, 160);
  top: 5%;
  left: -2%;
  opacity: 0;
  font-size: 150px;
  font-weight: 600;
  transition: left 600ms, color 1200ms, opacity 500ms;
  transition-timing-function: cubic-bezier(0.4, 0.6, 0.7, 1); 
  /* transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1); */
  z-index: 9;
}
#logo {
  position: absolute;
  top: -8%;
  left: 48%;
  transform: translate(-50%, 50%);
  transition-duration: 500ms;
  opacity: 1;
}
#logo path:nth-child(1) {
  stroke-dasharray: 556.8px;
  stroke-dashoffset: 556.8px;
  animation: line-anim 1.7s ease forwards 1s;

}
#logo path:nth-child(2) {
  stroke-dasharray: 516.3px;
  stroke-dashoffset: 516.3px;
  animation: line-anim 1.7s ease forwards 1.3s;

}
#logo path:nth-child(3) {
  stroke-dasharray: 309.1px;
  stroke-dashoffset: 309.1px;
  animation: line-anim 1.7s ease forwards 1.6s;

}
@keyframes line-anim{
  to{
    stroke-dashoffset: 0;
  }
}
@keyframes fill{
  to{
    fill: #BBBBBB;
  }
}
.reveal-container {
  position: fixed;
  z-index: 3;
  margin-top: 28vh;
  margin-left: 0;
  height: 500px;
  width: 50%;
  opacity: 0;
  text-align: left;
}

.blurb-line-1 {
  display: inline-block;
  opacity: 1;
  transition: top 600ms;
  transition-delay: 200ms;
  transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  position: relative;
  width: 600px;
  text-align: left;
  top: 200px;
  left: 24%;
  color: #000;
  font-size: 30px;
  z-index: 4;
}
.the-box-1 {
  height: 60px;
  width: 100%;
  background-color: rgb(117, 117, 117);
  transition: background-color 1000ms;
  transition-timing-function: cubic-bezier(0.1, 0.2, 1.0, 0.7); 
  position: relative;
  top: 150px;
  left: 0;
  z-index: 5;
  opacity: 1;
}
.blurb-line-2 {
  display: inline-block;
  width: auto;
  text-align: left;
  opacity: 1;
  transition: top 600ms;
  transition-delay: 400ms;
  transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  position: relative;
  top: 140px;
  left: 24%;
  color: #fff;
  font-size: 30px;
  z-index: 6;
}
.the-box-2 {
  height: 60px;
  width: 100%;
  background-color: rgb(117, 117, 117);
  transition: background-color 1000ms;
  transition-timing-function: cubic-bezier(0.1, 0.2, 1.0, 0.7); 
  position: relative;
  top: 100px;
  left: 0;
  z-index: 7;
  opacity: 1;
}

.yellow-line-wrapper {
  display: inline-block;
  position: relative;
  width: auto;
  top: 10px;
  left: 0px;
  font-size: 30px;
  z-index: 8;
}
.yellow-line {
  height: 2px;
  width: 50px;
  background-color: #FFCD00;
  margin-top: 0px;
  margin-left: -220px;
  position: relative;
  z-index: 8;
  transition: margin-left 500ms, opacity 500ms;
  transition-delay: 1000ms;
  opacity: 0;
}
.scroll-down-wrapper {
  position: fixed;
  top: 110vh;
  height: 100px;
  width: 100%;
  z-index: 8;
  opacity: 0;
  transition: top 500ms, opacity 500ms;
}
.scroll-word {
  position: absolute;
  transform: rotate(270deg);
  height: 200px;
  width: 200px;
  font-size: 14px;
  color: rgb(160, 160, 160);
  z-index: 8;
  top: -80%;
  left: 48%;
  animation: scroll-blink 1.5s ease-in-out infinite;
}
@keyframes scroll-blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.scroll-down-arrow {
  position: relative;
  background-image: url(img/arrow.svg);
  background-repeat: no-repeat;
  transform: rotate(90deg);
  width: 20px;
  height: 20px;
  left: 48%;
  top: 50px;
  animation: scroll-blink 1.5s ease-in-out infinite;
}
/* ----------------------------------VIDEO-SECTION--------------------------------- */

.next-section {
  height: 700px;
  width: 100vw;
  margin-top: 170vh;
  background-color: #fff;
  background-size: cover;
  position: relative;
  z-index: 12;
}
.embedded-video {
  width: 80%;
  height: 90%;
  padding-top: 50px;
  opacity: 0;
  transition-duration: 600ms;
}

/* rgb(19, 134, 109) */



.top-header {
  width: 100%;
  background-color: #fff;
  height: 50px;
  top: 0;
  position: fixed;
  z-index: 19;
}
.header-title {
  float: left;
  margin-left: 50px;
  margin-top: 15px;
}
.left-header {
  height: 100%;
  width: 50px;
  background-color: #fff;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 19;
}
.right-header {
  height: 100%;
  width: 50px;
  background-color: #fff;
  top: 0;
  right: 0;
  position: fixed;
  z-index: 19;
  transition-duration: 300ms;
}
.linkedIn {
  background-image: url(img/linkedin.png);
  background-size: cover;
  background-position: center;
  top: 42%;
  margin-top: 10px;
  left: 10px;
  height: 30px;
  width: 30px;
  position: relative;
  z-index: 20;
  cursor: pointer;
  transition-duration: 300ms;
  float: right;
  margin-right: 20px;
}
.linkedIn:hover{
  top: 40%;
  height: 45px;
  width: 45px;
  margin-right: 25px;
}
.envelope {
  background-image: url(img/envelope.png);
  background-size: cover;
  background-position: center;
  top: 44%;
  left: 10px;
  height: 20px;
  width: 30px;
  position: relative;
  z-index: 20;
  cursor: pointer;
  transition-duration: 300ms;
  float: right;
  margin-right: 20px;
}
.envelope:hover{
  top: 44%;
  height: 30px;
  width: 45px;
  margin-right: 25px;
}
.github {
  background-image: url(img/github.png);
  background-size: cover;
  background-position: center;
  top: 45%;
  margin-top: 10px;
  left: 10px;
  height: 30px;
  width: 30px;
  position: relative;
  z-index: 20;
  cursor: pointer;
  transition-duration: 300ms;
  float: right;
  margin-right: 20px;
}
.github:hover{
  top: 45%;
  height: 45px;
  width: 45px;
  margin-right: 25px;
}
.bottom-header {
  width: 100%;
  background-color: #fff;
  height: 50px;
  bottom: 0;
  position: fixed;
  z-index: 39;
}
.bottom-email {
  margin-top: 15px;
  color: #004B87;
  display: none;
}



/* --------------------------BIO-SECTION------------------------- */

.bio-section {
  height: 700px;
  width: 100vw;
  margin-top: 1500px;
  background-color: #fff;
  background-size: cover;
  position: relative;
  z-index: 12;
}
.bio-title {
  font-size: 80px;
  font-weight: 600;
  margin-top: 200px;
  margin-left: 10%;
  color: #004B87;
  position: absolute;
  z-index: 13;
}
.bio-pitch {
  font-size: 24px;
  font-weight: 600;
  width: 400px;
  text-align: left;
  margin-top: 350px;
  margin-left: 10%;
  position: absolute;
}
.period {
  color: #004B87;
}
.bio-paragraph {
  font-size: 16px;
  color: rgb(83, 83, 83);
  width: 400px;
  text-align: left;
  margin-top: 450px;
  margin-left: 10%;
  position: absolute;
}
.skill-box {
  background-color: rgb(41, 41, 41);
  width: 700px;
  height: 500px;
  margin-left: 50%;
  top: 600px;
  position: relative;
}
.skill-column {
  width: 200px;
  padding: 40px 20px;
  height: 200px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  position: relative;
  display: inline-block;
}
.list-item {
  color: rgb(121, 121, 121);
  font-weight: 300;
  padding: 5px;
  font-size: 14px;
}






/* --------------------------PROJECTS-SECTION------------------------- */


.projects-section {
  height: 900px;
  width: 100vw;
  margin-top: 300px;
  background-color: rgb(202, 202, 202);
  position: relative;
  z-index: 15;
}
.projects-title {
  margin-top: 50px;
  transform: translate(-50%);
  margin-left: 50%;
  font-size: 80px;
  font-weight: 600;
  color: #004B87;
  position: absolute;
}
.project-wrapper {
  position: absolute;
  margin-top: 200px;
  margin-left: 10%;
  z-index: 16;
  width: 80%;
  height: auto;
}
.picture-card {
  width: 300px;
  height: 225px;
  display: inline-block;
  background-position: center;
  background-size: 310px 225px;
  margin: 10px 10px;
  border-radius: 10px;
  vertical-align: top;
  text-decoration: none;
  position: relative;
  z-index: 20;
}
.project-card {
  width: 300px;
  height: 225px;
  display: inline-block;
  background-position: center;
  background-size: cover;
  margin: 10px 10px;
  border-radius: 10px;
  background-color: #000;
  vertical-align: top;
  text-decoration: none;
  position: absolute;
  opacity: 1;
}
.main-card-div {
  display: inline-block;
}
.each-card {
  display: inline-block;
  opacity: 0;
  transition-duration: 1000ms;
}
.picture-card:hover {
  opacity: 0;
  transition: 800ms;
  cursor: pointer;
  /* transform: scale(1.5); */
  background-size: 360px 269px;
}
.project-card:hover > .project-card {
  opacity: 1;
}
.project-card:hover > .card-text-wrapper {
  opacity: 1;
  transition-duration: 300ms;
}
.project-card:hover > .card-text-wrapper {
  opacity: 1;
  transition-duration: 300ms;
}

.card-text-wrapper {
  position: relative;
  opacity: 1;
  width: 100%;
  height: 100%;
}
.project-name {
  position: relative;
  font-size: 30px;
  font-weight: 100;
  color: #fff;
  z-index: 17;
  width: 100%;
  top: -30px;
  /*WHERE IT MOVES TO ----> top: 40px; */
  text-decoration: none;
  display: block;
  opacity: 0;
  transition-duration: 500ms;
}
.project-tools {
  position: relative;
  font-size: 14px;
  font-weight: 100;
  color: rgb(255, 220, 79);
  z-index: 17;
  top: 45px;
  text-decoration: none;
  text-align: center;
  display: block;
  opacity: 0;
}
@keyframes flicker{
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
.project-description {
  position: relative;
  width: 80%;
  margin-left: 10%;
  font-size: 14px;
  font-weight: 100;
  color: #fff;
  z-index: 17;
  top: 120px;
  /* WHERE IT MOVES TO ----> top: 70px; */
  opacity: 0;
  text-decoration: none;
}
.bio-pic {
  margin-top: -325px;
  margin-left: 35%;
  margin-bottom: 50px;
  height: 200px;
  width: 200px;
  background-image: url(img/prescott.png);
  background-size: cover;
  background-position: center;
  border-radius: 50%;
}
.contact-info {
  margin-top: -170px;
  margin-left: 28%;
  margin-bottom: 50px;
  font-size: 40px;
  height: 200px;
  width: 800px;
}





/* ------------------------------------------ALT-SCREENSIZES--------------------------------------------- */




@media(max-width: 1200px) {
  .projects-section {
    height: 1000px;
  }
}
@media(max-width: 1120px){
  .blurb-line-1 {
    width: 400px;
    font-size: 20px;
    left: 80px;
  }
  .blurb-line-2 {
    width: auto;
    font-size: 21px;
    left: 80px;
  }
  .yellow-line {
    width: 60px;
    display: none;
  }
  .the-box-1 {
    width: 92%;
  }
  .bio-pitch {
    width: 100vw;
  }
  .bio-paragraph {
    margin-top: 450px;
    width: 60%;
  }
  .skill-box {
    top: 900px;
    margin-left: 0;
    width: 70vw;
    left: 15vw;
    height: 600px;
    vertical-align: top;
  }
  .projects-section {
    margin-top: 600px;

  }
}
@media(max-width: 1156px) {
  .blurb-line-1, .blurb-line-2 {
    left: 80px;
  }
  .yellow-line {
    left: -17%;
  }
}
@media(max-width: 900px) {
  .left-header, .right-header {
    display: none;
  }
  .bottom-email {
    display: block;
  }
  .bio-title, .bio-pitch, .bio-paragraph {
    margin-left: 10%;
  }
}
@media(max-width: 800px) {
  .bio-pitch {
    width: 80%;
  }
  .projects-section {
    height: 1700px;
  }
  .bio-pic {
    margin-top: -600px;
  }
  .contact-info {
    margin-top: 0;
    margin-left: 0%;
  }
}
@media(max-width: 710px) {
  .blurb-line-1 {
    font-size: 16px;
  }
  .blurb-line-2 {
    font-size: 17px;
  }
  .yellow-line {
    left: -26%;
  }
  .scroll-word, .scroll-down-arrow {
    left: 45%;
  }
  .skill-column {
    width: 25%;
  }
}
@media(max-width: 600px) {
  .blurb-line-1 {
    font-size: 14px;
  }
  .blurb-line-2 {
    font-size: 15px;
  }
  .yellow-line {
    left: -30%;
  }
  .contact-info {
    margin-left: -25%;
  }
  .hej-wrapper {
    margin-left: 10vw;
    width: 80vw;
  }
  .reveal-container {
    width: 50vw;
  }
  .scroll-word, .scroll-down-arrow {
    left: 45vw;
  }
}

@media(max-width: 501px) {
  body {
    overflow-x: hidden;
    width: 100vw;
  }
  .first-section {
    display: none;
  }
  .first-section-mobile {
    display: block;
    background-color: #004B87;
    width: 100vw;
    height: 100vh;
  }
  .hej-mobile {
    font-size: 100px;
    color: #FFCD00;
    padding-top: 55vw;
  }
  .blurb-line-1-mobile {
    font-size: 22px;
    margin-top: 22px;
  }
  .blurb-line-2-mobile {
    color: #fff;
  }
  .bio-section {
    display: none;
  }
  .bio-section-mobile {
    display: block;
    width: 100vw;
    height: 100vh;
  }
  .bio-title-mobile {
    font-size: 40px;
    margin-top: 50px;
    font-weight: bold;
    color: #004B87;
  }
  .bio-pitch-mobile {
    font-size: 20px;
    width: 80%;
    margin-left: 10%;
    color: rgb(58, 58, 58);
    margin-top: 10px;
  }
  .bio-paragraph-mobile {
    width: 70%;
    margin-left: 15%;
    margin-top: 20px;
  }
  .skill-box-mobile {
    width: 90%;
    margin-left: 5%;
    margin-top: 40px;
    background-color: #BBBBBB;
  }
  .skill-column-mobile {
    width: 40%;
    display: inline-block;
    margin: 20px 10px;
    vertical-align: top;
  }
  .column-title-mobile {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
  }
  .projects-section {
    display: none;
  }
  .projects-title-mobile {
    font-size: 40px;
    color: #004B87;
    margin-top: 250px;
    margin-bottom: 50px;
  }
  .projects-section-mobile {
    display: block;
    height: 90vh;
  }
  .each-card {
    opacity: 1;
  }
  .project-wrapper-mobile {
    width: 100vw;
  }
  .picture-card {
    width: 150px;
    height: 112px;
    background-size: cover;
    display: none;
  }
  .picture-card-mobile {
    width: 150px;
    height: 112px;
    background-size: cover;
    display: inline-block;
    background-position: center;
    margin: 10px 10px;
    border-radius: 10px;
    vertical-align: top;
    text-decoration: none;
    position: relative;
    z-index: 20;
    cursor: pointer;
  }
  .each-card {
    display: none;
  }
  .each-card-mobile {
    display: inline-block;
  }
  .project-card {
    width: 150px;
    height: 112px;
  }
  .bio-pic {
    display: none;
  }
  .contact-info {
    display: none;
  }
  .picture-card:hover {
    opacity: 1;
    background-size: cover;
  }
  .modal-wrapper {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.884);
    position: fixed;
    z-index: 50;
  }
  .modal-content {
    width: 80vw;
    height: 80vh;
    margin-top: 10vh;
    margin-left: 10vw;
    background-color: #fff;
    border-radius: 10px;

  }
  .modal-name {
    color: #004B87;
    padding-top: 100px;
    font-size: 40px;
    margin-bottom: 40px;
  }
  .modal-description {
    margin-left: 10%;
    width: 80%;
    margin-bottom: 30px;
  }
  .modal-tools {
    text-decoration: underline;
    margin-bottom: 100px;
  }
  .modal-x {
    float: right;
    margin-top: 30px;
    margin-right: 30px;
    cursor: pointer;
  }
  .modal-link {
    text-decoration: none;
    color: #fff;
    background-color: #004B87;
    font-size: 18px;
    border: 2px solid #004B87;
    border-radius: 10px;
    padding: 10px;
  }
}
